export const validateEmail = (email) => {
  return IsValidEmail(email);
};

// export const validatePassword = (password = "") => {
//   return validatePasswordCheck(password);
//   // const pattern =
//   //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;
//   // const isValid = pattern.test(password);
//   // if (isValid) return { isValid, message: "" };
//   // return {
//   //   isValid,
//   //   message:
//   //     "Minimum 8 characters, at least one letter, one number and one special character, Maximum 20 characters",
//   // };
// };

export const validatePassword = (password) => {
  const pattern =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&+\-=~`])[A-Za-z\d@$!%*#?&+\-=~`]{8,}$/;
  const isValid = pattern.test(password);
  if (isValid) return { isValid, message: "" };
  return {
    isValid,
    message:
      "Minimum 8 characters, at least one letter, one number and one special character",
  };
};

const IsValidEmail = (email) => {
  if (!email || email.indexOf("@") === -1) {
    return { isValid: false, message: "Enter valid email Id." }; // Error if '@' is not present
  }

  return { isValid: true, message: "" }; // Valid if '@' is present
};

const validatePasswordCheck = (password) => {
  // Check if password is at least 8 characters long
  if (password.length === 0) {
    return {
      isValid: false,
      message: "Enter a valid Password.",
    };
  } else if (password.length < 8) {
    return {
      isValid: false,
      message:
        "Minimum 8 characters, at least one letter, one number and one special character, Maximum 20 characters",
    };
  } else if (password.length > 20) {
    return {
      isValid: false,
      message:
        "Minimum 8 characters, at least one letter, one number and one special character, Maximum 20 characters",
    };
  }

  let character = false;
  for (let i = 0; i < password.length; i++) {
    const char = password.charAt(i);
    if ((char >= "a" && char <= "z") || (char >= "A" && char <= "Z")) {
      character = true;
    }
  }
  if (!character) {
    return {
      isValid: false,
      message:
        "Minimum 8 characters, at least one letter, one number and one special character, Maximum 20 characters",
    };
  }

  // Check if password contains at least one digit
  let hasDigit = false;
  for (let i = 0; i < password.length; i++) {
    if (!isNaN(password[i])) {
      hasDigit = true;
      break;
    }
  }
  if (!hasDigit) {
    return {
      isValid: false,
      message:
        "Minimum 8 characters, at least one letter, one number and one special character, Maximum 20 characters",
    };
  }

  let specialCharater = hasSpecialCharacter(password);

  if (!specialCharater) {
    return {
      isValid: false,
      message:
        "Minimum 8 characters, at least one letter, one number and one special character, Maximum 20 characters",
    };
  }

  // Password meets all requirements
  return { isValid: true, message: "" };
};

const hasSpecialCharacter = (str) => {
  const specialChars = "!@#$%^&*()_+{}[]|;:',./<>?\\\""; // List of special characters
  for (let i = 0; i < str.length; i++) {
    if (specialChars.indexOf(str[i]) !== -1) {
      return true; // Special character found
    }
  }
  return false; // Special character not found
};
